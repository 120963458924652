import React from 'react'
import { Flex, Box } from 'reflexbox'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Copy, ButtonLink } from '../page/components'

export default ({ isAccount }) => {
  return (
    <Flex flexWrap="nowrap">
      <Box width="100%">
        <Copy>
          <p>
            Your current student membership to The Guide has now expired. But,
            don’t worry, you can keep the 20+ years of content, created and
            curated by Shillington’s passionate teachers, at your fingertips.
            There are resources to help you throughout your creative career—from
            the job hunt to technical help. All for just $10 USD/$12 AUD/£10 a
            month.
          </p>
          <h3>Shillumni Membership includes:</h3>
          <ul>
            <li>
              Exclusive access to the Career Guide—packed with industry insights
              to help you set goals and start your career.
            </li>
            <li>
              All the content from the Shillington course to refer back to.
            </li>
            <li>
              Relevant content that constantly evolves with the design industry
              to ensure The Guide remains as up-to-date as possible.
            </li>
            <li>
              Fresh design inspiration, case studies and industry examples.
            </li>
            <li>
              Searchable, portable, animated content created to support our
              graduates.
            </li>
          </ul>
        </Copy>
      </Box>
    </Flex>
  )
}

{
  /* <ButtonLink>
<a href="mailto:theguide@shillingtoneducation.com">
  Report a technical issue
</a>
</ButtonLink> */
}
